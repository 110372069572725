import React, { useEffect, useState, useRef } from 'react';
import { useTable, useFilters } from 'react-table';
import axios from 'axios';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { useSensor, useSensors, MouseSensor, TouchSensor, KeyboardSensor } from '@dnd-kit/core';
import Modal from 'react-modal';
import EditableCell from './EditableCell'
import EditableDropdown from './EditableDropdown'
import AudioUpload from './AudioUpload'; // Import the AudioUpload component
import AudioLibraryManager from './AudioLibraryManager'; // Import the AudioLibraryManager component
import SortableRow from './SortableRow';
import * as XLSX from 'sheetjs-style';

// Popup Modal Component with Editable Fields
const PopupModal = ({
  isOpen,
  onClose,
  content,
  title,
  updateMyData,
  rowIndex,
  getFilteredOptions,
  heroType,
}) => {
  const [localContent, setLocalContent] = useState(content);

  useEffect(() => {
    setLocalContent(content);
  }, [content]);

  const customStyles = {
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    },
    content: {
      position: 'relative',
      maxWidth: '600px',
      width: '90%',
      border: 'none',
      borderRadius: '12px',
      padding: '30px',
      backgroundColor: '#fff',
      boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
      animation: 'fadeIn 0.2s ease-in-out',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
  };

  const handleChange = (field, value) => {
    setLocalContent((prev) => {
      let updatedContent = prev.map((item) =>
        item.field === field ? { ...item, value } : item
      );

      // Handle when 'type' (attackType, effectType, skillType) changes
      if (field.includes('attackType') || field.includes('effectType') || field.includes('skillType')) {
        const isTypeSelected = value && value !== '';

        updatedContent = updatedContent.map((item) => {
          if (item.field.includes('keyId') && field.includes(item.field.split('.')[0])) {
            const options = isTypeSelected ? getFilteredOptions(heroType, item.field, updatedContent) : [];
            const firstValidOption = options.find((opt) => opt && opt !== 'Select') || '';

            return {
              ...item,
              options,
              value: isTypeSelected ? firstValidOption : '',
              disabled: !isTypeSelected,
            };
          }

          // Reset all dependent fields when 'type' is not selected
          const dependsOnType = [
            'effectPower',
            'triggerOnAttackCount',
            'probability',
            'effectDuration',
            'skillDamage',
            'cooldown',
            'skillName',
            'skillDescription'
          ].some((suffix) => item.field.includes(suffix) && field.includes(item.field.split('.')[0]));

          if (dependsOnType) {
            return {
              ...item,
              value: isTypeSelected ? item.value || 0 : 0, // Always use 0 for dependent fields
              disabled: !isTypeSelected,
            };
          }

          return item;
        });
      }

      // Handle when 'keyId' changes
      if (field.includes('keyId')) {
        const isKeyIdSelected = value && value !== '';

        updatedContent = updatedContent.map((item) => {
          const dependsOnKeyId = [
            'effectPower',
            'triggerOnAttackCount',
            'probability',
            'effectDuration',
            'skillDamage',
            'cooldown',
            'skillName',
            'skillDescription'
          ].some((suffix) => item.field.includes(suffix) && field.includes(item.field.split('.')[0]));

          if (dependsOnKeyId) {
            return {
              ...item,
              value: isKeyIdSelected ? item.value || 0 : 0, // Always use 0 for dependent fields
              disabled: !isKeyIdSelected,
            };
          }

          return item;
        });
      }

      return updatedContent;
    });
  };


  const handleSave = () => {
    const typeItem = localContent.find((item) =>
      item.field.includes('attackType') ||
      item.field.includes('effectType') ||
      item.field.includes('skillType')
    );
    const keyIdItem = localContent.find((item) => item.field.includes('keyId'));

    // Reset all dependent fields if Type is not selected
    if (typeItem && (!typeItem.value || typeItem.value === 'Select')) {
      const updatedContent = localContent.map((item) => {
        if (item.field !== typeItem.field) {
          return {
            ...item,
            value: item.inputType === 'number' ? 0 : '', // Reset fields to 0 for numeric inputs
            disabled: true,
          };
        }
        return item;
      });

      setLocalContent(updatedContent);
      updatedContent.forEach((item) => {
        updateMyData(rowIndex, item.field, item.value);
      });
      onClose();
      return;
    }

    // Check if Key ID is valid when Type is selected
    if (
      typeItem &&
      typeItem.value &&
      keyIdItem &&
      (!keyIdItem.value || keyIdItem.value === 'Select')
    ) {
      alert('Please select a valid Key ID when Type is selected.');
      return;
    }

    // Save data, ensuring numeric fields are saved as 0 if blank
    localContent.forEach((item) => {
      const valueToSave =
        item.inputType === 'number' && (item.value === '' || item.value === null)
          ? 0 // Ensure 0 is saved for numeric fields
          : item.value;
      updateMyData(rowIndex, item.field, valueToSave);
    });
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      ariaHideApp={false}
      contentLabel={title}
      style={customStyles}
    >
      <h2 style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: '20px' }}>{title}</h2>
      {title === 'Side Effect Attack' && (
        <div style={{
          backgroundColor: '#f8f9fa',
          padding: '10px',
          borderRadius: '6px',
          marginBottom: '15px',
          border: '1px solid #dee2e6',
          fontSize: '14px',
          textAlign: 'center',
          color: '#dc3545'
        }}>
          <strong>Note:</strong> Set probability to 0 if triggerOnAttackCount &gt; 0.
          <br />
          Set triggerOnAttackCount to 0 if probability &gt; 0.
        </div>
      )}
      <div>
        {localContent.map((item, i) => (
          <div
            key={i}
            className="modal-item"
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              marginBottom: '12px',
            }}
          >
            <label
              style={{
                fontWeight: '500',
                fontSize: '14px',
                width: '35%',
                textAlign: 'right',
                marginRight: '10px',
                color: item.field.includes('keyId') || item.field.includes('Type') ? 'red' : '#000',
              }}
            >
              {item.label}
              {(item.field.includes('keyId') || item.field.includes('Type')) && (
                <span style={{ color: 'red', marginLeft: '5px' }}>*</span>
              )}
              :
            </label>
            {item.type === 'select' ? (
              <EditableDropdown
                value={item.value}
                row={{ index: rowIndex }}
                column={{ id: item.field }}
                updateMyData={(index, id, value) => handleChange(id, value)}
                options={item.options}
                disabled={item.disabled}
                style={{
                  flex: 1,
                  borderRadius: '6px',
                  padding: '8px',
                  border: '1px solid #ddd',
                }}
              />
            ) : (
              <EditableCell
                value={item.value}
                row={{ index: rowIndex }}
                column={{ id: item.field }}
                updateMyData={(index, id, value) => handleChange(id, value)}
                type={item.inputType || 'text'}
                disabled={item.disabled}
                style={{
                  flex: 1,
                  borderRadius: '6px',
                  padding: '8px',
                  border: '1px solid #ddd',
                }}
              />
            )}
          </div>
        ))}
      </div>
      <div className="modal-actions" style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
        <button
          onClick={onClose}
          style={{
            padding: '10px 20px',
            backgroundColor: '#6c757d',
            color: '#fff',
            border: 'none',
            borderRadius: '6px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = '#5a6268')}
          onMouseOut={(e) => (e.target.style.backgroundColor = '#6c757d')}
        >
          Close
        </button>
        <button
          onClick={handleSave}
          style={{
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: '#fff',
            border: 'none',
            borderRadius: '6px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease',
          }}
          onMouseOver={(e) => (e.target.style.backgroundColor = '#0056b3')}
          onMouseOut={(e) => (e.target.style.backgroundColor = '#007bff')}
        >
          Save
        </button>
      </div>
    </Modal>
  );
};

// Main Hero Data Component
function HeroData() {
  const [data, setData] = useState([]);
  const [commonData, setCommonData] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState([]);
  const [modalTitle, setModalTitle] = useState('');
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [audioFilesChanged, setAudioFilesChanged] = useState(false); // Track audio changes
  const [showAudioLibrary, setShowAudioLibrary] = useState(false);
  const [currentAudioCell, setCurrentAudioCell] = useState({ rowIndex: null, columnId: null, context: 'hero' });
  const modalRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      try {
        // Fetch hero data
        const [heroResponse, commonResponse] = await Promise.all([
          axios.get('/api/hero-data', { headers: { Authorization: `Bearer ${token}` } }),
          axios.get('/api/common-data', { headers: { Authorization: `Bearer ${token}` } }),
        ]);

        const heroesWithIds = heroResponse.data.heroes.map((hero, index) => ({
          ...hero,
          baseAttackAudio: hero.baseAttackAudio || '', // Add baseAttackAudio property
          sideEffectAttackAudio: hero.sideEffectAttackAudio || '', // Add sideEffectAttackAudio property
          unique_id: `${index}-${Date.now()}`,
        }));
        setData(heroesWithIds);
        setCommonData(commonResponse.data); // Ensure this is set
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    // Close audio library modal when clicking outside
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowAudioLibrary(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Save hero data
  const saveData = () => {
    const invalidRows = data.filter((row) => !row.keyId || row.keyId === '' || row.keyId === "Select");
    if (invalidRows.length > 0) {
      alert('Please ensure all rows have a valid Key ID selected before saving.');
      return; // Prevent saving
    }

    const token = localStorage.getItem('token');
    axios
      .post(
        '/api/save-hero-data',
        { heroes: data },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(() => {
        alert('Hero data saved successfully!');
        setAudioFilesChanged(false); // Reset audio files changed flag
      })
      .catch((error) => console.error('Error saving hero data:', error));
  };

  // Update data in table
  const updateMyData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          // Check if updating the audio column
          if ((columnId === 'baseAttackAudio' || columnId === 'sideEffectAttackAudio') &&
            ((row[columnId] || value) && row[columnId] !== value)) {
            setAudioFilesChanged(true);
          }

          const keys = columnId.split('.');
          let updatedRow = { ...row };

          // If it's a direct property (not nested)
          if (keys.length === 1) {
            updatedRow[columnId] = value || (typeof value === 'number' ? 0 : '');
            return updatedRow;
          }

          // Handle nested properties
          let objRef = updatedRow;
          for (let i = 0; i < keys.length - 1; i++) {
            const key = keys[i];
            if (!objRef[key]) {
              objRef[key] = {}; // Initialize missing nested objects
            }
            objRef = objRef[key];
          }
          objRef[keys[keys.length - 1]] = value || (typeof value === 'number' ? 0 : '');
          return updatedRow;
        }
        return row;
      })
    );
  };

  // Functions for audio library integration
  const handleOpenAudioLibrary = (rowIndex, columnId, context = 'hero') => {
    setCurrentAudioCell({ rowIndex, columnId, context });
    setShowAudioLibrary(true);
  };

  const handleSelectAudio = (audioPath) => {
    if (currentAudioCell.rowIndex !== null && currentAudioCell.columnId) {
      updateMyData(currentAudioCell.rowIndex, currentAudioCell.columnId, audioPath);
      setShowAudioLibrary(false);
    }
  };

  // Drag and Drop Logic
  const handleDragEnd = (event) => {
    const { active, over } = event;

    // Prevent error if 'over' is null
    if (!over) return;

    if (active.id !== over.id) {
      setData((oldData) => {
        const oldIndex = oldData.findIndex((row) => row.unique_id === active.id);
        const newIndex = oldData.findIndex((row) => row.unique_id === over.id);
        return arrayMove(oldData, oldIndex, newIndex);
      });
    }
  };

  const flattenData = (data) => {
    return data.map((row) => {
      const { unique_id, baseAttack, skillAttack, baseAttackAudio, sideEffectAttackAudio, ...rest } = row;

      // Flatten baseAttack and its nested sideEffectAttack
      const { sideEffectAttack, ...baseAttackRest } = baseAttack || {};
      const { keyId: sideEffectKeyId, ...sideEffectRest } = sideEffectAttack || {};

      // Flatten skillAttack
      const { keyId: skillAttackKeyId, ...skillAttackRest } = skillAttack || {};

      return {
        ...rest,
        baseAttackKeyId: baseAttackRest?.keyId || "",
        baseAttackType: baseAttackRest?.attackType || "",
        baseAttackDamage: baseAttackRest?.damage || 0,
        sideEffectKeyId: sideEffectKeyId || "",
        ...sideEffectRest,
        skillAttackKeyId: skillAttackKeyId || "",
        ...skillAttackRest,
        // Don't include audio fields in Excel export
      };
    });
  };

  const exportToExcel = () => {
    if (!data || data.length === 0) {
      alert("No data available to export");
      return;
    }

    const flattenedData = flattenData(data); // Flatten the data before export
    const worksheet = XLSX.utils.json_to_sheet(flattenedData);

    // Apply styles to headers
    const range = XLSX.utils.decode_range(worksheet["!ref"]); // Get the range of the worksheet
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C }); // Header row cells (row 0)
      if (!worksheet[cellAddress]) continue;

      // Apply styles
      worksheet[cellAddress].s = {
        font: { bold: true, color: { rgb: "000000" } }, // Bold black font
        fill: { patternType: "solid", fgColor: { rgb: "DDEBF7" } }, // Light blue background
        alignment: { horizontal: "center", vertical: "center" }, // Center alignment
      };
    }

    // Set column widths (autofit based on content)
    const colWidths = Object.keys(flattenedData[0]).map((key) => {
      const maxContentLength = Math.max(
        key.length, // Header length
        ...flattenedData.map((row) => String(row[key] || "").length) // Content length
      );
      return { wch: maxContentLength + 2 }; // Add padding
    });
    worksheet["!cols"] = colWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Hero Data");

    XLSX.writeFile(workbook, "HeroData.xlsx");
  };

  const importFromExcel = (event) => {
    const file = event.target.files[0]; // Get the uploaded file
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });

      // Assuming data is in the first sheet
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Convert sheet to JSON
      const importedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Preserve existing audio values if they exist
      const audioMap = {};
      data.forEach(row => {
        if (row.keyId) {
          audioMap[row.keyId] = {
            baseAttackAudio: row.baseAttackAudio || '',
            sideEffectAttackAudio: row.sideEffectAttackAudio || ''
          };
        }
      });

      // Map imported data to the required nested JSON format based on the specified header order
      const formattedData = importedData.slice(1).map((row, index) => {
        const keyId = row[0] || '';
        const savedAudio = audioMap[keyId] || { baseAttackAudio: '', sideEffectAttackAudio: '' };

        return {
          keyId: keyId,
          heroType: row[1] || '',
          heroTier: row[2] || '',
          heroName: row[3] || '',
          attackSpeed: row[4] || 0,
          attackRange: row[5] || 0,
          moveSpeed: row[6] || 0,
          critical: row[7] || 0,
          spawnProbability: row[8] || 0,
          price: row[9] || 0,
          heroDescription: row[10] || '',
          armorPenetration: row[11] || 0,
          magicPenetration: row[12] || 0,
          critDamageMuliplier: row[13] || 1.0,
          baseAttack: {
            keyId: row[14] || '',
            attackType: row[15] || '',
            damage: row[16] || 0,
            sideEffectAttack: {
              keyId: row[17] || '',
              effectType: row[18] || '',
              effectPower: row[19] || 0,
              triggerOnAttackCount: row[20] || 0,
              probability: row[21] || 0,
              effectDuration: row[22] || 0,
            },
          },
          baseAttackAudio: savedAudio.baseAttackAudio, // Preserve baseAttackAudio
          sideEffectAttackAudio: savedAudio.sideEffectAttackAudio, // Preserve sideEffectAttackAudio
          skillAttack: {
            keyId: row[23] || '',
            skillType: row[24] || '',
            skillDamage: row[25] || 0,
            cooldown: row[26] || 0,
            skillName: row[27] || '',
            skillDescription: row[28] || '',
          },
          unique_id: `${index}-${Date.now()}`, // Generate unique ID
        };
      });

      setData(formattedData);
      alert('Data imported successfully!');
      event.target.value = '';
    };

    reader.readAsBinaryString(file); // Read the file as binary
  };

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,  // Drag activates after moving 8 pixels
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,    // Drag activates after holding for 200ms
        tolerance: 6,  // Small tolerance for finger movements
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  // Open modal with appropriate content and pass rowIndex for updates
  const openModal = (title, content, rowIndex) => {
    const row = data[rowIndex]; // Current row data
    const dynamicContent = content.map((item) => {
      const { field } = item;

      // Handle nested keys dynamically
      const value = field.split('.').reduce((obj, key) => obj?.[key], row);

      // Determine options dynamically
      let options = item.options;

      if (field.includes('sideEffectAttack.keyId')) {
        const effectType = row.baseAttack?.sideEffectAttack?.effectType || '';
        options = getFilteredOptions(row.heroType, 'sideEffectAttack.keyId', [
          ...content,
          { field: 'baseAttack.sideEffectAttack.effectType', value: effectType },
        ]);
      }

      if (field.includes('skillAttack.keyId')) {
        const skillType = row.skillAttack?.skillType || '';
        options = getFilteredOptions(row.heroType, 'skillAttack.keyId', [
          ...content,
          { field: 'skillAttack.skillType', value: skillType },
        ]);
      }

      if (field.includes('skillAttack.skillType')) {
        options = getFilteredOptions(row.heroType, 'skillAttack.skillType', content);
      }

      // Check dependencies to set field states based on type and keyId
      const typeField = content.find((c) => c.field.includes('Type'));
      const keyIdField = content.find((c) => c.field.includes('keyId'));

      if (typeField?.field === field) {
        // Type field logic
        return { ...item, value: value || '', disabled: false };
      }

      if (keyIdField?.field === field) {
        // KeyId field logic
        const isTypeSelected = typeField?.value && typeField.value !== '';
        return {
          ...item,
          value: isTypeSelected ? value || '' : '',
          disabled: !isTypeSelected,
          options: isTypeSelected ? options : [],
        };
      }

      // Other input fields dependent on type and keyId
      const dependsOnKeyId =
        field.includes('effectPower') ||
        field.includes('triggerOnAttackCount') ||
        field.includes('probability') ||
        field.includes('effectDuration') ||
        field.includes('skillDamage') ||
        field.includes('cooldown') ||
        field.includes('skillName') ||
        field.includes('skillDescription');

      if (dependsOnKeyId) {
        const isTypeAndKeyIdSelected = typeField?.value && keyIdField?.value;
        return {
          ...item,
          value: isTypeAndKeyIdSelected ? value || 0 : 0,
          disabled: !isTypeAndKeyIdSelected,
        };
      }

      return { ...item, value };
    });

    setModalTitle(title);
    setModalContent(dynamicContent);
    setCurrentRowIndex(rowIndex);

    // Delay the modal opening to ensure all states are updated first
    setTimeout(() => {
      setModalIsOpen(true);
    }, 0);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const addRow = () => {
    const newRow = {
      unique_id: `${data.length}-${Date.now()}`, // Generate unique ID
      keyId: '', // Set default values for new row
      heroType: '',
      heroTier: '',
      heroName: '',
      attackSpeed: 0.00,
      attackRange: 0.00,
      moveSpeed: 0.00,
      armorPenetration: 0.00, // New field
      magicPenetration: 0.00, // New field
      critical: 0.00, // Default for Critical
      critDamageMuliplier: 1.00, // Default for Crit Damage Multiplier
      spawnProbability: 0.00,
      price: 0.00,
      heroDescription: '', // New field
      baseAttack: {
        keyId: '',
        attackType: '',
        damage: 0.00,
        sideEffectAttack: {
          keyId: '',
          effectType: '',
          effectPower: 0.00,
          triggerOnAttackCount: 0,
          probability: 0,
          effectDuration: 0,
        },
      },
      baseAttackAudio: '', // Add baseAttackAudio property
      sideEffectAttackAudio: '', // Add sideEffectAttackAudio property
      skillAttack: {
        keyId: '',
        skillType: '',
        skillDamage: 0.00,
        cooldown: 0.00,
        skillName: '',
        skillDescription: '',
      },
    };
    setData((oldData) => [...oldData, newRow]);
  };

  // Remove a row from the data
  const removeRow = (unique_id) => {
    setData((oldData) => oldData.filter((row) => row.unique_id !== unique_id));
  };

  function getFilteredOptions(heroType, keyField, localContent = []) {
    if (!commonData || !Object.keys(commonData).length) {
      return <p>Loading...</p>; // or show an appropriate message
    }

    // Find the hero type in commonData
    const heroData = commonData.heroes.find((hero) => hero.heroType === heroType);
    if (!heroData) return [];

    if (keyField === 'keyId') {
      // Return the keyId array for the specified heroType
      return heroData.keyId || [];
    }

    if (keyField.includes('sideEffectAttack')) {
      const sideEffectField = keyField.split('.').pop(); // e.g., "effectType" or "keyId"
      if (sideEffectField === 'effectType') {
        // Return effect types that have non-empty keyId arrays
        return heroData.sideEffectAttack?.effects
          .filter((effect) => effect.keyId && effect.keyId.length > 0)
          .map((effect) => effect.effectType) || [];
      }
      if (sideEffectField === 'keyId') {
        const effectType = localContent.find((item) =>
          item.field.includes('sideEffectAttack.effectType')
        )?.value;
        const selectedEffect = heroData.sideEffectAttack?.effects?.find(
          (effect) => effect.effectType === effectType
        );
        return selectedEffect ? selectedEffect.keyId : [];
      }
    }

    if (keyField.includes('baseAttack')) {
      const baseAttackField = keyField.split('.').pop(); // e.g., "keyId" or "attackType"
      if (baseAttackField === 'keyId') {
        // Return the keyId array for baseAttack
        return heroData.baseAttack.keyId || [];
      }
      if (baseAttackField === 'attackType') {
        // Return attack types that have non-empty keyId arrays
        return heroData.baseAttack.keyId && heroData.baseAttack.keyId.length > 0 ? [heroData.baseAttack.attackType || ''] : [];
      }
    }

    // Handle skillAttack.keyId
    if (keyField.includes('skillAttack')) {
      const skillAttackField = keyField.split('.').pop(); // e.g., "keyId" or "skillType"
      if (skillAttackField === 'skillType') {
        // Return skill types that have non-empty keyId arrays
        return heroData.skillAttack?.skills
          .filter((skill) => skill.keyId && skill.keyId.length > 0)
          .map((skill) => skill.skillType) || [];
      }
      if (skillAttackField === 'keyId') {
        const skillType = localContent.find((item) =>
          item.field.includes('skillAttack.skillType')
        )?.value;
        const selectedSkill = heroData.skillAttack?.skills.find(
          (skill) => skill.skillType === skillType
        );
        return selectedSkill ? selectedSkill.keyId : [];
      }
    }

    return [];
  }

  const columns = React.useMemo(() => {
    const {
      commonTiers = [],
      attackTypes = [],
      heroes = [],
    } = commonData;

    const heroTypes = heroes.map((hero) => hero.heroType);

    return [
      {
        Header: '', // DnD handle column
        id: 'dnd',
        Cell: () => (
          <span className="drag-handle" style={{ cursor: 'grab' }}>☰</span>
        ),
      },
      {
        Header: 'Key ID',
        accessor: 'keyId',
        width: 150,
        Cell: (props) => {
          const options = getFilteredOptions(props.row.original.heroType, 'keyId');
          return <EditableDropdown {...props} options={options} />;
        }
      },
      { Header: 'Hero Type', accessor: 'heroType', Cell: (props) => <EditableDropdown {...props} options={heroTypes} /> },
      { Header: 'Hero Tier', accessor: 'heroTier', Cell: (props) => <EditableDropdown {...props} options={commonTiers} /> },
      { Header: 'Hero Name', accessor: 'heroName', Cell: EditableCell },
      { Header: 'Attack Speed', accessor: 'attackSpeed', Cell: (props) => <EditableCell {...props} type="number" /> },
      { Header: 'Attack Range', accessor: 'attackRange', Cell: (props) => <EditableCell {...props} type="number" /> },
      { Header: 'Move Speed', accessor: 'moveSpeed', Cell: (props) => <EditableCell {...props} type="number" /> },

      // Add new penetration columns
      { Header: 'Armor Penetration', accessor: 'armorPenetration', Cell: (props) => <EditableCell {...props} type="number" /> },
      { Header: 'Magic Penetration', accessor: 'magicPenetration', Cell: (props) => <EditableCell {...props} type="number" /> },

      // Add Critical and CritDamageMuliplier
      { Header: 'Critical(%)', accessor: 'critical', Cell: (props) => <EditableCell {...props} type="number" /> },
      { Header: 'Crit Damage Multiplier', accessor: 'critDamageMuliplier', Cell: (props) => <EditableCell {...props} type="number" /> },

      // Popup Buttons for Base Attack, Side Effect Attack, and Skill Attack
      {
        Header: 'Base Attack',
        accessor: 'baseAttack',
        Cell: ({ row }) => (
          <button
            className="btn btn-primary"
            onClick={() =>
              openModal('Base Attack', [
                {
                  label: 'Attack Type',
                  value: row.original.baseAttack?.attackType,
                  field: 'baseAttack.attackType',
                  type: 'select',
                  options: getFilteredOptions(row.original.heroType, 'baseAttack.attackType'),
                },
                {
                  label: 'Key ID',
                  value: row.original.baseAttack?.keyId,
                  field: 'baseAttack.keyId',
                  type: 'select',
                  options: getFilteredOptions(row.original.heroType, 'baseAttack.keyId'),
                },
                { label: 'Damage', value: row.original.baseAttack?.damage, field: 'baseAttack.damage', inputType: 'number' },
              ], row.index)
            }
          >
            Base Attack
          </button>
        ),
      },
      // Audio fields for heroes
      {
        Header: 'Base Attack Audio',
        accessor: 'baseAttackAudio',
        Cell: (props) => <AudioUpload
          {...props}
          showAudioLibrary={handleOpenAudioLibrary}
          context="hero"
        />,
      },
      {
        Header: 'Skill Attack',
        accessor: 'skillAttack',
        Cell: ({ row }) => (
          <button
            className="btn btn-primary"
            onClick={() => openModal('Skill Attack', [
              {
                label: 'Skill Type',
                value: row.original.skillAttack?.skillType,
                field: 'skillAttack.skillType',
                type: 'select',
                options: getFilteredOptions(row.original.heroType, 'skillAttack.skillType', modalContent)
              },
              {
                label: 'Key ID',
                value: row.original.skillAttack?.keyId,
                field: 'skillAttack.keyId',
                type: 'select',
                options: getFilteredOptions(row.original.heroType, 'skillAttack.keyId', modalContent)
              },
              {
                label: 'Skill Name',
                value: row.original.skillAttack?.skillName,
                field: 'skillAttack.skillName',
                inputType: 'text'
              },
              {
                label: 'Skill Description',
                value: row.original.skillAttack?.skillDescription,
                field: 'skillAttack.skillDescription',
                inputType: 'text'
              },
              { label: 'Skill Damage', value: row.original.skillAttack?.skillDamage, field: 'skillAttack.skillDamage', inputType: 'number' },
              { label: 'Cooldown', value: row.original.skillAttack?.cooldown, field: 'skillAttack.cooldown', inputType: 'number' }
            ], row.index)}
          >
            Skill Attack
          </button>
        ),
      },
      {
        Header: 'Side Effect Attack',
        accessor: 'sideEffectAttack',
        Cell: ({ row }) => (
          <button
            className="btn btn-primary"
            onClick={() =>
              openModal('Side Effect Attack', [
                {
                  label: 'Effect Type',
                  value: row.original.baseAttack?.sideEffectAttack?.effectType,
                  field: 'baseAttack.sideEffectAttack.effectType',
                  type: 'select',
                  options: getFilteredOptions(row.original.heroType, 'sideEffectAttack.effectType', modalContent),
                },
                {
                  label: 'Key ID',
                  value: row.original.baseAttack?.sideEffectAttack?.keyId,
                  field: 'baseAttack.sideEffectAttack.keyId',
                  type: 'select',
                  options: getFilteredOptions(row.original.heroType, 'sideEffectAttack.keyId', modalContent),
                },
                { label: 'Effect Power %', value: row.original.baseAttack?.sideEffectAttack?.effectPower, field: 'baseAttack.sideEffectAttack.effectPower', inputType: 'number' },
                { label: 'Trigger on Attack Count', value: row.original.baseAttack?.sideEffectAttack?.triggerOnAttackCount, field: 'baseAttack.sideEffectAttack.triggerOnAttackCount', inputType: 'number' },
                { label: 'Probability', value: row.original.baseAttack?.sideEffectAttack?.probability, field: 'baseAttack.sideEffectAttack.probability', inputType: 'number' },
                { label: 'Duration', value: row.original.baseAttack?.sideEffectAttack?.effectDuration, field: 'baseAttack.sideEffectAttack.effectDuration', inputType: 'number' }
              ], row.index)
            }
          >
            Side Effect Attack
          </button>
        ),
      },
      {
        Header: 'Side Effect Attack Audio',
        accessor: 'sideEffectAttackAudio',
        Cell: (props) => <AudioUpload
          {...props}
          showAudioLibrary={handleOpenAudioLibrary}
          context="hero"
        />,
      },
      // Other general fields (e.g., spawnProbability, price)
      { Header: 'Spawn Probability', accessor: 'spawnProbability', Cell: (props) => <EditableCell {...props} type="number" /> },
      { Header: 'Price', accessor: 'price', Cell: (props) => <EditableCell {...props} type="number" /> },
      { Header: 'Hero Description', accessor: 'heroDescription', Cell: EditableCell },
      {
        Header: 'Actions',
        id: 'actions',
        Cell: ({ row }) => (
          <button className="btn btn-danger" onClick={() => removeRow(row.original.unique_id)}>
            Remove
          </button>
        ),
      },
    ];
  }, [data, commonData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data, updateMyData }, useFilters);

  return (
    <div className="container" style={{ height: '100%', overflow: 'hidden' }}>
      <h1 className="text-center">Hero Data</h1>

      {audioFilesChanged && (
        <div className="alert alert-danger custom-alert" role="alert" style={{ backgroundColor: '#f8d7da', borderColor: '#f5c6cb', color: '#721c24' }}>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <strong>Warning:</strong> 오디오 파일이 수정되었습니다. 반드시 Save 버튼을 눌러 저장해주세요.
            </div>
          </div>
        </div>
      )}

      <div className="actions-container">
        <button className="save-button" onClick={saveData}>Save Hero Data</button>
        <button className="save-button" onClick={exportToExcel}>Export to Excel</button>
        <button className="save-button" onClick={addRow}>Add Row</button>
        <input
          type="file"
          accept=".xlsx, .xls"
          onChange={importFromExcel}
          className="import-input"
        />
        <button
          className="audio-library-button"
          onClick={() => {
            setCurrentAudioCell({ rowIndex: null, columnId: null, context: 'hero' });
            setShowAudioLibrary(true);
          }}
        >
          Manage Hero Audio
        </button>
      </div>

      <div className="table-container">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}
        >
          <SortableContext items={rows.map((row) => row.original.unique_id)}>
            <table {...getTableProps()} className="custom-table">
              <thead>
                {headerGroups.map((headerGroup) => {
                  const { key, ...restHeaderProps } = headerGroup.getHeaderGroupProps(); // Extract key
                  return (
                    <tr key={key} {...restHeaderProps}>
                      {headerGroup.headers.map((column) => {
                        const { key: columnKey, ...restColumnProps } = column.getHeaderProps(); // Extract key for <th>
                        return (
                          <th key={columnKey} {...restColumnProps}>
                            {column.render('Header')}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  const { key, ...restRowProps } = row.getRowProps(); // Extract key for <tr>
                  return (
                    <SortableRow key={key} row={row}>
                      {row.cells.map((cell) => {
                        const { key: cellKey, ...restCellProps } = cell.getCellProps(); // Extract key for <td>
                        return (
                          <td key={cellKey} {...restCellProps}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </SortableRow>
                  );
                })}
              </tbody>
            </table>
          </SortableContext>
        </DndContext>
      </div>

      <PopupModal
        isOpen={modalIsOpen}
        onClose={closeModal}
        content={modalContent}
        title={modalTitle}
        updateMyData={updateMyData}
        rowIndex={currentRowIndex}
        getFilteredOptions={getFilteredOptions}
        heroType={data[currentRowIndex]?.heroType || ''} // Pass the heroType of the current row
      />

      {/* Audio Library Modal */}
      {showAudioLibrary && (
        <div className="audio-library-modal-overlay">
          <div className="audio-library-modal" ref={modalRef}>
            <div className="audio-library-modal-header">
              <h3>{currentAudioCell.rowIndex !== null ? "Select Audio" : "Manage Audio Library"}</h3>
              <button
                className="close-modal-btn"
                onClick={() => setShowAudioLibrary(false)}
              >
                ✕
              </button>
            </div>
            <div className="audio-library-modal-body">
              <AudioLibraryManager
                onSelectAudio={handleSelectAudio}
                mode={currentAudioCell.rowIndex !== null ? "select" : "manage"}
                context={currentAudioCell.context || 'hero'}
                onDeleteAudio={(audioPath, fileName) => {
                  // When an audio is deleted in manage mode, update any rows using it
                  if (currentAudioCell.rowIndex === null) {
                    // Find all rows using this audio and clear them
                    const updatedData = data.map(row => {
                      if (row.baseAttackAudio === audioPath) {
                        return { ...row, baseAttackAudio: '' };
                      }
                      if (row.sideEffectAttackAudio === audioPath) {
                        return { ...row, sideEffectAttackAudio: '' };
                      }
                      return row;
                    });

                    // If any rows were updated, set the data
                    if (JSON.stringify(data) !== JSON.stringify(updatedData)) {
                      setData(updatedData);
                      setAudioFilesChanged(true);
                    }
                  }
                }}
              />
            </div>
            <div className="audio-library-modal-footer">
              <button
                className="btn btn-secondary"
                onClick={() => setShowAudioLibrary(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default HeroData;