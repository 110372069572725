import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandAlt, faCompressAlt } from '@fortawesome/free-solid-svg-icons';

const EditableExpandableCell = ({
  value: initialValue,
  row: { index },
  column: { id, type = 'text' },
  updateMyData,
}) => {
  const [value, setValue] = useState(initialValue);
  const [expanded, setExpanded] = useState(false);
  const textareaRef = useRef(null);
  const containerRef = useRef(null);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    updateMyData(index, id, value);
  };

  const handleExpandToggle = (e) => {
    e.stopPropagation();
    setExpanded(!expanded);
    // Focus the textarea after expanding
    if (!expanded && textareaRef.current) {
      setTimeout(() => {
        textareaRef.current.focus();
      }, 10);
    }
  };

  return (
    <div 
      ref={containerRef} 
      className="editable-expandable-cell-container"
      style={{ 
        position: 'relative',
        width: '100%',
        maxWidth: '100%'
      }}
    >
      <textarea
        ref={textareaRef}
        value={value || ''}
        onChange={onChange}
        onBlur={onBlur}
        style={{
          width: '100%',
          boxSizing: 'border-box',
          minHeight: expanded ? '120px' : '30px',
          height: expanded ? '120px' : '30px',
          padding: '5px 25px 5px 5px',
          resize: 'none',
          transition: 'height 0.3s ease',
          overflow: expanded ? 'auto' : 'hidden',
          whiteSpace: expanded ? 'normal' : 'nowrap',
          textOverflow: 'ellipsis',
          lineHeight: '1.2',
          border: '1px solid #ccc',
          borderRadius: '4px',
          fontSize: '1rem'
        }}
      />
      <button
        type="button"
        onClick={handleExpandToggle}
        style={{
          position: 'absolute',
          bottom: expanded ? 'auto' : '3px',
          top: expanded ? '3px' : 'auto',
          right: '3px',
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          fontSize: '14px',
          color: '#555',
          padding: '2px',
          zIndex: 10,
          width: '20px',
          height: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        title={expanded ? "Collapse" : "Expand"}
      >
        <FontAwesomeIcon icon={expanded ? faCompressAlt : faExpandAlt} />
      </button>
    </div>
  );
};

export default EditableExpandableCell;