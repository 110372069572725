import React from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

function PrivateRoute({ children }) {
  const token = localStorage.getItem('token');

  if (!token) {
    return <Navigate to="/login" />;
  }

  try {
    // Decode token to check its validity and expiration
    const decodedToken = jwtDecode(token);
    const isExpired = decodedToken.exp * 1000 < Date.now(); // Token expiry is in seconds
    if (isExpired) {
      localStorage.removeItem('token'); // Clear expired token
      return <Navigate to="/login" />;
    }
  } catch (err) {
    console.error('Invalid token:', err);
    localStorage.removeItem('token'); // Remove invalid token
    return <Navigate to="/login" />;
  }

  return children;
}

export default PrivateRoute;
