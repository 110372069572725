import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useTable } from 'react-table';
import axios from 'axios';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { KeyboardSensor, useSensor, useSensors, MouseSensor, TouchSensor } from '@dnd-kit/core';
import EditableCell from './EditableCell';
import EditableDropdown from './EditableDropdown';
import AudioUpload from './AudioUpload'; // Import the audio upload component
import SortableRow from './SortableRow';
import AudioLibraryManager from './AudioLibraryManager'; // Import the audio library manager
import * as XLSX from 'sheetjs-style';

function EnemyData() {
  const [data, setData] = useState([]);
  const [keyIdOptions, setKeyIdOptions] = useState([]);
  const [enemyTypeOptions, setEnemyTypeOptions] = useState([]);
  const [isDataModified, setIsDataModified] = useState(false);
  const [audioFilesChanged, setAudioFilesChanged] = useState(false);
  const [showAudioLibrary, setShowAudioLibrary] = useState(false);
  const [currentAudioCell, setCurrentAudioCell] = useState({ rowIndex: null, columnId: null, context: 'enemy' });
  const tableRef = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem('token');

    // Set fixed enemy type options
    setEnemyTypeOptions(['Normal', 'MiniBoss', 'MainBoss']);

    // Fetch both enemy data and common data
    Promise.all([
      axios.get('/api/enemy-data', {
        headers: { Authorization: `Bearer ${token}` },
      }),
      axios.get('/api/common-data', {
        headers: { Authorization: `Bearer ${token}` },
      })
    ])
      .then(([enemyResponse, commonResponse]) => {
        // Process enemy data
        const enemiesWithId = enemyResponse.data.enemies.map((enemy, index) => ({
          ...enemy,
          audio: enemy.audio || '', // Add audio property with default empty string
          unique_id: `${index}-${Date.now()}`, // Generate unique ID for each row
        }));
        setData(enemiesWithId);

        // Get keyIds from common data
        if (commonResponse.data.enemies && Array.isArray(commonResponse.data.enemies)) {
          const commonKeyIds = commonResponse.data.enemies.map(enemy => enemy.keyId);
          setKeyIdOptions(commonKeyIds);
        }
      })
      .catch((error) => console.error('Error fetching data:', error));

    // Close audio library modal when clicking outside
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowAudioLibrary(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const saveData = () => {
    const token = localStorage.getItem('token');
    axios
      .post('/api/save-enemy-data', { enemies: data }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        alert('Enemy data saved successfully!');
        setIsDataModified(false);
        setAudioFilesChanged(false);
      })
      .catch((error) => console.error('Error saving enemy data:', error));
  };

  const moveRow = useCallback((activeIndex, overIndex) => {
    if (activeIndex !== overIndex) {
      setData((old) => arrayMove(old, activeIndex, overIndex));
      setIsDataModified(true);
    }
  }, []);

  const updateMyData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          // If updating the audio column, check if we need to show the notification
          if (columnId === 'audio' && row.audio !== value && (row.audio || value)) {
            setAudioFilesChanged(true);
          }

          return {
            ...row,
            [columnId]: value,
          };
        }
        return row;
      })
    );
    setIsDataModified(true);
  };

  const addRow = () => {
    const newRow = {
      keyId: '',
      enemyType: '',
      level: 0,
      hp: 0,
      moveSpeed: 0,
      enemyCount: 0,
      armor: 0,
      magicArmor: 0,
      gold: 0,
      waveTime: 0,
      audio: '', // Add default empty audio property
      unique_id: `${data.length}-${Date.now()}`, // Ensure a unique ID
    };
    setData((old) => [...old, newRow]);
    setIsDataModified(true);
    scrollToBottom();
  };

  const removeRow = (index) => {
    setData((old) => old.filter((_, i) => i !== index));
    setIsDataModified(true);
  };

  const scrollToBottom = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const flattenData = (data) => {
    return data.map((row) => ({
      keyId: row.keyId || '',
      enemyType: row.enemyType || '',
      level: row.level || 0,
      hp: row.hp || 0,
      moveSpeed: row.moveSpeed || 0,
      enemyCount: row.enemyCount || 0,
      armor: row.armor || 0,
      magicArmor: row.magicArmor || 0,
      gold: row.gold || 0,
      waveTime: row.waveTime || 0,
      // Audio is excluded from the Excel export
    }));
  };

  const exportToExcel = () => {
    if (!data || data.length === 0) {
      alert('No data available to export');
      return;
    }

    const flattenedData = flattenData(data);
    const worksheet = XLSX.utils.json_to_sheet(flattenedData);

    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
      if (!worksheet[cellAddress]) continue;
      worksheet[cellAddress].s = {
        font: { bold: true, color: { rgb: '000000' } },
        fill: { patternType: 'solid', fgColor: { rgb: 'DDEBF7' } },
        alignment: { horizontal: 'center', vertical: 'center' },
      };
    }

    const colWidths = Object.keys(flattenedData[0]).map((key) => {
      const maxContentLength = Math.max(
        key.length,
        ...flattenedData.map((row) => String(row[key] || '').length)
      );
      return { wch: maxContentLength + 2 };
    });
    worksheet['!cols'] = colWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Enemy Data');

    XLSX.writeFile(workbook, 'EnemyData.xlsx');
  };

  const importFromExcel = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      const importedData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      // Preserve existing audio values if they exist
      const audioMap = {};
      data.forEach(row => {
        if (row.keyId && row.audio) {
          audioMap[row.keyId] = row.audio;
        }
      });

      const formattedData = importedData.slice(1).map((row, index) => {
        const keyId = row[0] || '';
        return {
          keyId: keyId,
          enemyType: row[1] || '',
          level: row[2] || 0,
          hp: row[3] || 0,
          moveSpeed: row[4] || 0,
          enemyCount: row[5] || 0,
          armor: row[6] || 0,
          magicArmor: row[7] || 0,
          gold: row[8] || 0,
          waveTime: row[9] || 0,
          audio: audioMap[keyId] || '', // Preserve audio from existing data if available
          unique_id: `${index}-${Date.now()}`,
        };
      });

      setData(formattedData);
      alert('Data imported successfully!');
      event.target.value = '';
      setIsDataModified(true);
    };

    reader.readAsBinaryString(file);
  };

  // Function to open the audio library modal for a specific cell
  const handleOpenAudioLibrary = (rowIndex, columnId, context = 'enemy') => {
    setCurrentAudioCell({ rowIndex, columnId, context });
    setShowAudioLibrary(true);
  };

  // Function to handle selecting an audio from the library
  const handleSelectAudio = (audioPath) => {
    if (currentAudioCell.rowIndex !== null && currentAudioCell.columnId) {
      updateMyData(currentAudioCell.rowIndex, currentAudioCell.columnId, audioPath);
      setShowAudioLibrary(false);
    }
  };

  // Function to apply the same audio to multiple rows
  const applyAudioToSelected = (audioPath) => {
    // Get all selected rows (you can add a selection mechanism in your table)
    const selectedRows = window.confirm('Apply this audio to all rows?') ?
      Array.from({ length: data.length }, (_, i) => i) :
      [currentAudioCell.rowIndex];

    selectedRows.forEach(rowIndex => {
      updateMyData(rowIndex, 'audio', audioPath);
    });

    setShowAudioLibrary(false);
  };

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 8 } }),
    useSensor(TouchSensor, { activationConstraint: { delay: 200, tolerance: 6 } }),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const columns = React.useMemo(
    () => [
      { Header: '', id: 'drag', Cell: () => <span className="drag-handle">&#9776;</span> },
      { Header: 'Key ID', accessor: 'keyId', Cell: (props) => <EditableDropdown {...props} options={keyIdOptions} /> },
      { Header: 'Enemy Type', accessor: 'enemyType', Cell: (props) => <EditableDropdown {...props} options={enemyTypeOptions} /> },
      { Header: 'Level', accessor: 'level', Cell: EditableCell, type: 'number' },
      { Header: 'HP', accessor: 'hp', Cell: EditableCell, type: 'number' },
      { Header: 'Move Speed', accessor: 'moveSpeed', Cell: EditableCell, type: 'number' },
      { Header: 'Enemy Count', accessor: 'enemyCount', Cell: EditableCell, type: 'number' },
      { Header: 'Armor', accessor: 'armor', Cell: EditableCell, type: 'number' },
      { Header: 'Magic Armor', accessor: 'magicArmor', Cell: EditableCell, type: 'number' },
      { Header: 'Gold', accessor: 'gold', Cell: EditableCell, type: 'number' },
      { Header: 'Wave Time', accessor: 'waveTime', Cell: EditableCell, type: 'number' },
      {
        Header: 'Audio',
        accessor: 'audio',
        Cell: (props) => <AudioUpload
          {...props}
          showAudioLibrary={handleOpenAudioLibrary}
          context="enemy" // Specify context for enemy audio
        />
      },
      { Header: 'Actions', id: 'actions', Cell: ({ row }) => <button className="btn btn-danger" onClick={() => removeRow(row.index)}>Remove</button> },
    ],
    [keyIdOptions, enemyTypeOptions]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    updateMyData,
  });

  // Audio Library Modal
  const renderAudioLibraryModal = () => {
    if (!showAudioLibrary) return null;

    // Determine if we're in select mode (for a specific cell) or manage mode (global library)
    const isSelectMode = currentAudioCell.rowIndex !== null;
    const mode = isSelectMode ? "select" : "manage";
    const title = isSelectMode ? "Select Audio" : "Manage Audio Library";
    const context = currentAudioCell.context || 'enemy';

    return (
      <div className="audio-library-modal-overlay">
        <div className="audio-library-modal" ref={modalRef}>
          <div className="audio-library-modal-header">
            <h3>{title}</h3>
            <button
              className="close-modal-btn"
              onClick={() => setShowAudioLibrary(false)}
            >
              ✕
            </button>
          </div>
          <div className="audio-library-modal-body">
            <AudioLibraryManager
              onSelectAudio={handleSelectAudio}
              mode={mode}
              context={context}
              onDeleteAudio={(audioPath, fileName) => {
                // When an audio is deleted, update any rows using it
                if (mode === "manage") {
                  // Find all rows using this audio and clear them
                  const updatedData = data.map(row => {
                    if (row.audio === audioPath) {
                      return { ...row, audio: '' };
                    }
                    return row;
                  });

                  // If any rows were updated, set the data
                  if (JSON.stringify(data) !== JSON.stringify(updatedData)) {
                    setData(updatedData);
                    setIsDataModified(true);
                    setAudioFilesChanged(true);
                  }
                }
              }}
            />
          </div>
          <div className="audio-library-modal-footer">
            <button
              className="btn btn-secondary"
              onClick={() => setShowAudioLibrary(false)}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="container">
      <h1 className="text-center">Enemy Data</h1>

      {audioFilesChanged && (
        <div className="alert alert-danger custom-alert" role="alert" style={{ backgroundColor: '#f8d7da', borderColor: '#f5c6cb', color: '#721c24' }}>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <strong>Warning:</strong> 오디오 파일이 수정되었습니다. 반드시 Save 버튼을 눌러 저장해주세요.
            </div>
          </div>
        </div>
      )}

      <div className="actions-container">
        <button className="save-button" onClick={saveData} disabled={!isDataModified}>Save Enemy Data</button>
        <button className="save-button" onClick={exportToExcel}>Export to Excel</button>
        <button className="save-button" onClick={addRow}>Add Row</button>
        <input type="file" accept=".xlsx, .xls" onChange={importFromExcel} />
        <button
          className="audio-library-button"
          onClick={() => {
            setCurrentAudioCell({ rowIndex: null, columnId: null, context: 'enemy' });
            setShowAudioLibrary(true);
          }}
        >
          Manage Audio Library
        </button>
      </div>

      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={({ active, over }) => {
          if (active && over) {
            const activeIndex = rows.findIndex((row) => row.original.unique_id === active.id);
            const overIndex = rows.findIndex((row) => row.original.unique_id === over.id);
            moveRow(activeIndex, overIndex);
          }
        }}
        modifiers={[restrictToVerticalAxis]}
      >
        <div className="table-container">
          <table {...getTableProps()} className="custom-table" ref={tableRef}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              <SortableContext items={rows.map((row) => row.original.unique_id)}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <SortableRow key={row.original.unique_id} row={row} moveRow={moveRow}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </SortableRow>
                  );
                })}
              </SortableContext>
            </tbody>
          </table>
        </div>
      </DndContext>

      {/* Render the audio library modal */}
      {renderAudioLibraryModal()}
    </div>
  );
}

export default EnemyData;