import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useTable } from 'react-table';
import axios from 'axios';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { KeyboardSensor, useSensor, useSensors, MouseSensor, TouchSensor } from '@dnd-kit/core';
import EditableCell from './EditableCell';
import EditableDropdown from './EditableDropdown';
import EditableExpandableCell from './EditableExpandableCell';
import ImageUploadCell from './ImageUploadCell';
import EditableToggle from './EditableToggle'; // Import the new EditableToggle component
import SortableRow from './SortableRow';
import * as XLSX from 'sheetjs-style';

function RuneData() {
  const [data, setData] = useState([]);
  const [runeTypeOptions, setRuneTypeOptions] = useState([]);
  const [runeCategoryOptions, setRuneCategoryOptions] = useState(['Attack', 'Support']);
  const [isDataModified, setIsDataModified] = useState(false);
  const tableRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    
    // Fetch both rune data and common data
    Promise.all([
      axios.get('/api/rune-data', {
        headers: { Authorization: `Bearer ${token}` },
      }),
      axios.get('/api/common-data', {
        headers: { Authorization: `Bearer ${token}` },
      })
    ])
    .then(([runeResponse, commonResponse]) => {
      // Process rune data
      const runesWithId = runeResponse.data.runes.map((rune, index) => ({
        ...rune,
        // Set default value for upgradable if it doesn't exist
        upgradable: rune.upgradable !== undefined ? rune.upgradable : false,
        unique_id: `${index}-${Date.now()}`, // Generate unique ID for each row
      }));
      
      setData(runesWithId);

      // Get rune types from common data
      if (commonResponse.data.runeTypes && Array.isArray(commonResponse.data.runeTypes)) {
        setRuneTypeOptions(commonResponse.data.runeTypes.sort());
      } else {
        // Fallback to existing method if runeTypes not found in common data
        const uniqueRuneTypes = [...new Set(runesWithId.map((rune) => rune.runeType))].filter(Boolean);
        setRuneTypeOptions(uniqueRuneTypes.sort());
      }
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      // Initialize with empty data if API fails
      setData([]);
    });
  }, []);

  const saveData = () => {
    console.log("Saving rune data");
    const token = localStorage.getItem('token');
    axios
      .post('/api/save-rune-data', { runes: data }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        alert('Rune data saved successfully!');
        setIsDataModified(false);
      })
      .catch((error) => console.error('Error saving rune data:', error));
  };

  const moveRow = useCallback((activeIndex, overIndex) => {
    if (activeIndex !== overIndex) {
      setData((old) => arrayMove(old, activeIndex, overIndex));
      setIsDataModified(true);
    }
  }, []);

  const updateMyData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value,
          };
        }
        return row;
      })
    );
    setIsDataModified(true);
  };

  const addRow = () => {
    const newRow = {
      runeName: '',
      runeType: runeTypeOptions.length > 0 ? runeTypeOptions[0] : '',
      runeCategory: 'Attack',
      coefficientSS: 0,
      coefficientS: 0,
      coefficientA: 0,
      coefficientB: 0,
      runeDescription: '',
      runeImage: '', // Initialize with empty string
      upgradable: false, // Initialize the upgradable field
      unique_id: `${data.length}-${Date.now()}`, // Ensure a unique ID
    };
    setData((old) => [...old, newRow]);
    setIsDataModified(true);
    scrollToBottom();
  };

  const removeRow = (index) => {
    // Get the image path before removing the row
    const row = data[index];
    const imagePath = row?.runeImage;
    
    // If there's an image, extract the filename and delete it from the server
    if (imagePath && typeof imagePath === 'string' && imagePath.includes('/')) {
      const filename = imagePath.split('/').pop();
      
      if (filename) {
        const token = localStorage.getItem('token');
        axios.delete(`/api/delete-rune-image/${filename}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
        .then(() => console.log('Image deleted successfully'))
        .catch(error => console.error('Error deleting image:', error));
      }
    }
    
    // Remove the row from data
    setData((old) => old.filter((_, i) => i !== index));
    setIsDataModified(true);
  };

  const scrollToBottom = () => {
    if (tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  const flattenData = (data) => {
    return data.map((row) => ({
      runeName: row.runeName || '',
      runeType: row.runeType || '',
      runeCategory: row.runeCategory || '',
      coefficientSS: row.coefficientSS || 0,
      coefficientS: row.coefficientS || 0,
      coefficientA: row.coefficientA || 0,
      coefficientB: row.coefficientB || 0,
      runeDescription: row.runeDescription || '',
      runeImagePath: row.runeImage ? row.runeImage : '', // Store the path reference
      upgradable: row.upgradable || false, // Include upgradable field
    }));
  };

  const exportToExcel = () => {
    if (!data || data.length === 0) {
      alert('No data available to export');
      return;
    }

    const flattenedData = flattenData(data);
    const worksheet = XLSX.utils.json_to_sheet(flattenedData);

    const range = XLSX.utils.decode_range(worksheet['!ref']);
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cellAddress = XLSX.utils.encode_cell({ r: 0, c: C });
      if (!worksheet[cellAddress]) continue;
      worksheet[cellAddress].s = {
        font: { bold: true, color: { rgb: '000000' } },
        fill: { patternType: 'solid', fgColor: { rgb: 'DDEBF7' } },
        alignment: { horizontal: 'center', vertical: 'center' },
      };
    }

    const colWidths = Object.keys(flattenedData[0]).map((key) => {
      const maxContentLength = Math.max(
        key.length,
        ...flattenedData.map((row) => String(row[key] || '').length)
      );
      return { wch: maxContentLength + 2 };
    });
    worksheet['!cols'] = colWidths;

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Rune Data');

    XLSX.writeFile(workbook, 'RuneData.xlsx');
  };

  const importFromExcel = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      const binaryStr = e.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });

      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Get data as objects with headers instead of arrays
      const importedData = XLSX.utils.sheet_to_json(worksheet, { raw: false });
      
      console.log("Imported data:", importedData);

      // Create a mapping of rune names to existing image paths and upgradable status
      const dataMap = {};
      data.forEach(row => {
        if (row.runeName) {
          dataMap[row.runeName] = {
            runeImage: row.runeImage || '',
            upgradable: row.upgradable !== undefined ? row.upgradable : false
          };
        }
      });

      const formattedData = importedData.map((row, index) => {
        const runeName = row.runeName || '';
        // Try to match existing rune data by name
        const existingData = dataMap[runeName] || {};
        
        // Process upgradable field safely
        let upgradable = false;
        if (row.hasOwnProperty('upgradable')) {
          // Handle various string representations of boolean values
          if (typeof row.upgradable === 'boolean') {
            upgradable = row.upgradable;
          } else if (typeof row.upgradable === 'string') {
            upgradable = row.upgradable.toLowerCase() === 'true';
          } else if (typeof row.upgradable === 'number') {
            upgradable = row.upgradable === 1;
          }
        } else if (existingData.upgradable) {
          // Fall back to existing data if available
          upgradable = existingData.upgradable;
        }
        
        return {
          runeName,
          runeType: row.runeType || '',
          runeCategory: row.runeCategory || 'Attack',
          coefficientSS: parseFloat(row.coefficientSS) || 0,
          coefficientS: parseFloat(row.coefficientS) || 0,
          coefficientA: parseFloat(row.coefficientA) || 0,
          coefficientB: parseFloat(row.coefficientB) || 0,
          runeDescription: row.runeDescription || '',
          runeImage: existingData.runeImage || '', // Use the image path from dataMap
          upgradable: upgradable, // Use the properly parsed upgradable value
          unique_id: `${index}-${Date.now()}`,
        };
      });

      setData(formattedData);
      alert('Data imported successfully!');
      event.target.value = '';
      setIsDataModified(true);
    };

    reader.readAsBinaryString(file);
  };

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 8 } }),
    useSensor(TouchSensor, { activationConstraint: { delay: 200, tolerance: 6 } }),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const columns = React.useMemo(
    () => [
      { Header: '', id: 'drag', Cell: () => <span className="drag-handle">&#9776;</span> },
      { 
        Header: 'Rune Image', 
        accessor: 'runeImage', 
        Cell: (props) => <ImageUploadCell {...props} updateMyData={updateMyData} />
      },
      { Header: 'Rune Name', accessor: 'runeName', Cell: EditableCell },
      { 
        Header: 'Rune Type', 
        accessor: 'runeType', 
        Cell: (props) => <EditableDropdown {...props} options={runeTypeOptions} /> 
      },
      { 
        Header: 'Rune Category', 
        accessor: 'runeCategory', 
        Cell: (props) => <EditableDropdown {...props} options={runeCategoryOptions} /> 
      },
      { Header: 'Coef. SS', accessor: 'coefficientSS', Cell: EditableCell, type: 'number' },
      { Header: 'Coef. S', accessor: 'coefficientS', Cell: EditableCell, type: 'number' },
      { Header: 'Coef. A', accessor: 'coefficientA', Cell: EditableCell, type: 'number' },
      { Header: 'Coef. B', accessor: 'coefficientB', Cell: EditableCell, type: 'number' },
      { Header: 'Description', accessor: 'runeDescription', Cell: EditableExpandableCell },
      { 
        Header: 'Upgradable', 
        accessor: 'upgradable', 
        Cell: EditableToggle 
      },
      { 
        Header: 'Actions', 
        id: 'actions', 
        Cell: ({ row }) => <button className="btn btn-danger" onClick={() => removeRow(row.index)}>Remove</button> 
      },
    ],
    [runeTypeOptions, runeCategoryOptions]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
    updateMyData,
  });

  return (
    <div className="container" style={{ height: '100%', overflow: 'hidden' }}>
      <h1 className="text-center">Rune Data</h1>
      <button className="save-button" onClick={saveData} disabled={!isDataModified}>Save Rune Data</button>
      <button className="save-button" onClick={exportToExcel} style={{ marginLeft: '20px' }}>Export to Excel</button>
      <button className="save-button" onClick={addRow} style={{ marginLeft: '20px' }}>Add Row</button>
      <input type="file" accept=".xlsx, .xls" onChange={importFromExcel} style={{ display: 'inline-block', marginLeft: '20px' }} />
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={({ active, over }) => {
          if (active && over) {
            const activeIndex = rows.findIndex((row) => row.original.unique_id === active.id);
            const overIndex = rows.findIndex((row) => row.original.unique_id === over.id);
            moveRow(activeIndex, overIndex);
          }
        }}
        modifiers={[restrictToVerticalAxis]}
      >
        <div className="table-container">
          <table {...getTableProps()} className="custom-table" ref={tableRef}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              <SortableContext items={rows.map((row) => row.original.unique_id)}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <SortableRow key={row.original.unique_id} row={row} moveRow={moveRow}>
                      {row.cells.map((cell) => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </SortableRow>
                  );
                })}
              </SortableContext>
            </tbody>
          </table>
        </div>
      </DndContext>
    </div>
  );
}

export default RuneData;