import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ImageUploadCell = ({ value: initialValue, row, column: { id }, updateMyData }) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(initialValue || '');
  const [imageError, setImageError] = useState(false);

  // When component mounts or image URL changes, verify the image exists
  useEffect(() => {
    if (imageUrl) {
      // Reset error state when URL changes
      setImageError(false);
      
      // Verify the image exists on the server
      const verifyImage = async () => {
        try {
          // Extract filename from the URL
          const filename = imageUrl.split('/').pop().split('?')[0];
          const token = localStorage.getItem('token');
          
          const response = await axios.get(`/api/test-image/${filename}`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          
          // If image doesn't exist on server but we have a URL, log detailed info
          if (response.data && !response.data.exists) {
            
            // If server found a case-sensitive match, update the URL
            if (response.data.actualFilename) {
              const newUrl = `/images/${response.data.actualFilename}`;
              setImageUrl(newUrl);
              updateMyData(row.index, id, newUrl);
            }
          }
        } catch (error) {
          console.error('Error verifying image:', error);
        }
      };
      
      verifyImage();
    }
  }, [imageUrl, row.index, id, updateMyData]);

  const handleImageChange = async (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];

      // Check file size (limit to 2MB)
      if (file.size > 2 * 1024 * 1024) {
        alert('Image size should be less than 2MB');
        return;
      }

      setLoading(true);
      setImageError(false); // Reset error state

      try {
        // Create form data
        const formData = new FormData();
        formData.append('runeImage', file);
        formData.append('runeName', Math.random().toString(36).substring(2, 15)); // Generate a unique name

        // Upload the image to server
        const token = localStorage.getItem('token');
        const response = await axios.post('/api/upload-rune-image', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          }
        });

        // Get the image path from response
        const imagePath = response.data.imagePath;

        // Update local state
        setImageUrl(imagePath);

        // Update parent data
        updateMyData(row.index, id, imagePath);

      } catch (error) {
        console.error('Error uploading image:', error);
        alert('Failed to upload image');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleDownload = () => {
    if (!imageUrl) return;

    // Get rune name safely from the row data
    const runeName = row.original.runeName || 'unknown';

    // Get the filename from the image URL - handle URLs with query parameters
    const filename = imageUrl.split('/').pop().split('?')[0];

    // Create the download URL with the filename and rune name as a query parameter
    const token = localStorage.getItem('token');
    const downloadUrl = `/api/download-rune-image/${filename}?runeName=${encodeURIComponent(runeName)}`;

    // Fetch the image
    fetch(downloadUrl, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.status}`);
        }
        return response.blob();
      })
      .then(blob => {
        // Get cleaned rune name
        const cleanRuneName = runeName.replace(/[^\w\s-]/g, '').replace(/\s+/g, '-').toLowerCase();

        // Get file extension from the original filename
        const fileExt = filename.split('.').pop().toLowerCase() || 'jpg';

        // Create a local download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `rune-${cleanRuneName}.${fileExt}`;
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch(error => {
        console.error('Download failed:', error);
        alert('Failed to download the image. Please try again.');
      });
  };

  // Fixed helper function to get the full image URL with timestamp to prevent caching
  const getFullImageUrl = (path) => {
    if (!path) return '';
    
    // If the path already has the domain, use it as is (with a new timestamp)
    if (path.startsWith('http')) {
      // Just update the timestamp 
      const urlBase = path.split('?')[0];
      const cacheBuster = `?t=${new Date().getTime()}`;
      return `${urlBase}${cacheBuster}`;
    }

    // Ensure path starts with /images/
    let normalizedPath = path;
    if (!normalizedPath.startsWith('/')) {
      normalizedPath = `/${normalizedPath}`;
    }
    if (!normalizedPath.startsWith('/images/')) {
      normalizedPath = `/images/${normalizedPath.replace(/^\//, '')}`;
    }

    // Add a timestamp to bust cache
    const cacheBuster = `?t=${new Date().getTime()}`;
    
    // IMPORTANT: Don't use window.location.origin - use relative paths instead
    // This way the browser will request from the same server that served the page
    const fullUrl = `${normalizedPath}${cacheBuster}`;
    return fullUrl;
  };

  // Function to check if image exists and handle errors
  const handleImageError = (e) => {
    console.error('Image failed to load:', imageUrl);
    console.error('Full URL attempted:', e.target.src);
    console.error('Browser info:', navigator.userAgent);
    console.error('OS platform:', navigator.platform);
    
    // Try to fetch the image manually to see server response
    fetch(e.target.src)
      .then(response => {
        return response.text();
      })
      .then(text => {
      })
      .catch(err => console.error('Image fetch error:', err));
    
    // Set error state
    setImageError(true);
    
    // Replace with placeholder
    e.target.src = `/api/placeholder-image`;
    e.target.className = 'rune-image error';
  };

  return (
    <div className="image-upload-cell">
      {loading ? (
        <div className="loading-indicator">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-loader">
            <line x1="12" y1="2" x2="12" y2="6"></line>
            <line x1="12" y1="18" x2="12" y2="22"></line>
            <line x1="4.93" y1="4.93" x2="7.76" y2="7.76"></line>
            <line x1="16.24" y1="16.24" x2="19.07" y2="19.07"></line>
            <line x1="2" y1="12" x2="6" y2="12"></line>
            <line x1="18" y1="12" x2="22" y2="12"></line>
            <line x1="4.93" y1="19.07" x2="7.76" y2="16.24"></line>
            <line x1="16.24" y1="7.76" x2="19.07" y2="4.93"></line>
          </svg>
        </div>
      ) : imageUrl ? (
        <div className={`image-container ${imageError ? 'image-error' : ''}`} title="Click to change or right-click to download">
          <img
            src={getFullImageUrl(imageUrl)}
            alt="Rune"
            className="rune-image"
            onError={handleImageError}
          />
          {imageError && (
            <div className="error-overlay">
              Image not found on server
            </div>
          )}
          <div className="image-overlay">
            <label htmlFor={`upload-${row.index}`}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-2">
                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
              </svg>
            </label>
            <div onClick={handleDownload} style={{ marginLeft: '10px', cursor: 'pointer' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-download">
                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                <polyline points="7 10 12 15 17 10"></polyline>
                <line x1="12" y1="15" x2="12" y2="3"></line>
              </svg>
            </div>
          </div>
          <input
            id={`upload-${row.index}`}
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: 'none' }}
          />
        </div>
      ) : (
        <label htmlFor={`upload-${row.index}`} className="upload-placeholder">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus">
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
          <input
            id={`upload-${row.index}`}
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: 'none' }}
          />
        </label>
      )}
    </div>
  );
};

// Add some CSS for the error display
const style = document.createElement('style');
style.textContent = `
  .image-error {
    border: 1px solid #ff5555;
  }
  .error-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 0, 0, 0.7);
    color: white;
    padding: 4px;
    font-size: 10px;
    text-align: center;
  }
`;
document.head.appendChild(style);

export default ImageUploadCell;