import React from 'react';

// EditableToggle component for checkbox fields
// This needs to be imported in the RuneData.js file
const EditableToggle = ({ 
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData
}) => {
  // We need to keep and update the state of the cell
  const [value, setValue] = React.useState(initialValue || false);

  const onChange = e => {
    setValue(e.target.checked);
    updateMyData(index, id, e.target.checked);
  };

  // If the initialValue is changed externally, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue || false);
  }, [initialValue]);

  return (
    <div className="checkbox-container">
      <input
        type="checkbox"
        checked={value}
        onChange={onChange}
      />
    </div>
  );
};

export default EditableToggle;