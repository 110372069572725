import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

// mode can be "select" (for table cells) or "manage" (for global management)
const AudioLibraryManager = ({ 
  onSelectAudio, 
  mode = "select", 
  onDeleteAudio,
  context = "enemy" // Default to enemy if not specified
}) => {
  const [audioFiles, setAudioFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [playingAudio, setPlayingAudio] = useState(null);
  const audioRef = useRef(null);
  const fileInputRef = useRef(null);

  // Fetch available audio files when component mounts or context changes
  useEffect(() => {
    fetchAudioFiles();
  }, [context]);

  // Clean up audio playback when component unmounts
  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
    };
  }, []);

  // Fetch all available audio files from the server
  const fetchAudioFiles = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`/api/audio-files/${context}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setAudioFiles(response.data.audioFiles);
    } catch (error) {
      console.error('Error fetching audio files:', error);
      alert('Error loading audio library');
    } finally {
      setLoading(false);
    }
  };
  
  // Handle deleting an audio file
  const handleDeleteAudio = async (audioPath, fileName) => {
    if (!window.confirm(`Are you sure you want to delete "${fileName}"? This will affect all rows using this audio.`)) {
      return;
    }
    
    try {
      const token = localStorage.getItem('token');
      // Extract context and filename from path
      const pathParts = audioPath.split('/');
      const contextFromPath = pathParts[pathParts.length - 2];
      const filename = pathParts[pathParts.length - 1];
      
      await axios.delete(`/api/delete-audio/${contextFromPath}/${filename}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      // Remove the file from the list
      setAudioFiles(prev => prev.filter(file => file.path !== audioPath));
      
      // Notify parent component
      if (onDeleteAudio) {
        onDeleteAudio(audioPath, fileName);
      }
      
      alert('Audio file deleted successfully');
      
      // If we're playing this audio, stop it
      if (playingAudio === audioPath && audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
        setPlayingAudio(null);
      }
    } catch (error) {
      console.error('Error deleting audio file:', error);
      alert('Error deleting audio file');
    }
  };

  // Handle file upload
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Check if the file is an audio file
    const fileType = file.type;
    if (!fileType.startsWith('audio/')) {
      alert('Please select an audio file');
      event.target.value = '';
      return;
    }

    setUploadingFile(true);
    const formData = new FormData();
    formData.append('audioFile', file);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`/api/upload-audio?context=${context}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.audioPath) {
        // Add the new audio file to the list
        setAudioFiles(prev => [...prev, {
          path: response.data.audioPath,
          name: response.data.audioPath.split('/').pop()
        }]);
        alert('Audio uploaded successfully!');
      }
    } catch (error) {
      console.error('Error uploading audio:', error);
      alert('Error uploading audio file');
    } finally {
      setUploadingFile(false);
      // Clear the file input
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  // Toggle audio playback
  const togglePlay = (audioPath) => {
    // If we're already playing this audio, stop it
    if (playingAudio === audioPath && audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
      setPlayingAudio(null);
      return;
    }

    // If we're playing a different audio, stop that one first
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current = null;
    }

    // Play the new audio
    const audio = new Audio(audioPath);
    audioRef.current = audio;
    
    // Set up event handlers
    audio.onplay = () => setPlayingAudio(audioPath);
    audio.onpause = () => setPlayingAudio(null);
    audio.onended = () => {
      setPlayingAudio(null);
      audioRef.current = null;
    };
    
    // Play the audio
    audio.play().catch(err => {
      console.error('Error playing audio:', err);
      alert('Error playing audio file');
      setPlayingAudio(null);
      audioRef.current = null;
    });
  };

  // Extract original filename from the stored filename
  const getOriginalFileName = (filename) => {
    // Try to extract the original part before the timestamp
    const parts = filename.split('-');
    if (parts.length >= 2) {
      // The last part is usually the timestamp-extension
      // Join all parts except the last one
      return parts.slice(0, -1).join('-');
    }
    return filename;
  };

  // Filter audio files based on search term
  const filteredAudioFiles = audioFiles.filter(file => 
    file.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="audio-library-container">
      <div className="audio-library-header">
        <h3>{context === 'enemy' ? 'Enemy Audio Library' : 'Hero Audio Library'}</h3>
        <div className="audio-library-controls">
          <div className="search-container">
            <input
              type="text"
              placeholder="Search audio files..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="search-input"
            />
          </div>
          <div className="upload-container">
            <input
              type="file"
              id="audio-library-upload"
              accept="audio/*"
              onChange={handleFileUpload}
              style={{ display: 'none' }}
              ref={fileInputRef}
            />
            <label 
              htmlFor="audio-library-upload" 
              className={`upload-btn ${uploadingFile ? 'uploading' : ''}`}
            >
              {uploadingFile ? 'Uploading...' : 'Upload New Audio'}
            </label>
          </div>
        </div>
      </div>

      <div className="audio-files-list">
        {loading ? (
          <div className="loading-message">Loading audio files...</div>
        ) : filteredAudioFiles.length === 0 ? (
          <div className="empty-message">
            {searchTerm 
              ? 'No audio files match your search' 
              : 'No audio files available. Upload some!'
            }
          </div>
        ) : (
          filteredAudioFiles.map((file, index) => (
            <div key={index} className="audio-file-item">
              <div className="audio-file-name" title={file.name}>
                {getOriginalFileName(file.name) || file.name.length > 20 
                  ? `${getOriginalFileName(file.name) || file.name.substring(0, 17)}...` 
                  : file.name
                }
              </div>
              <div className="audio-file-controls">
                <button 
                  className={`btn-play ${playingAudio === file.path ? 'playing' : ''}`}
                  onClick={() => togglePlay(file.path)}
                  title={playingAudio === file.path ? "Stop" : "Play"}
                >
                  {playingAudio === file.path ? '■' : '▶'}
                </button>
                
                {mode === "select" ? (
                  <button 
                    className="btn-select" 
                    onClick={() => onSelectAudio(file.path)}
                    title="Use this audio"
                  >
                    Use
                  </button>
                ) : (
                  <button 
                    className="btn-delete" 
                    onClick={() => handleDeleteAudio(file.path, file.name)}
                    title="Delete this audio"
                  >
                    Delete
                  </button>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default AudioLibraryManager;