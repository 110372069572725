import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';

const AudioUpload = ({ 
  row: { index },
  cell: { value },
  column: { id },
  updateMyData,
  showAudioLibrary,
  context = 'enemy' // Default to 'enemy' if not specified
}) => {
  const [loading, setLoading] = useState(false);
  const [audioName, setAudioName] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [fileExists, setFileExists] = useState(false);
  const [checking, setChecking] = useState(true);
  const audioRef = useRef(null);
  const timeUpdateRef = useRef(null);
  
  // Extract context and filename from path
  const getContextAndFilename = (path) => {
    if (!path) return { context: '', filename: '' };
    
    const parts = path.split('/');
    // Handle both formats: /audio/context/filename and /audio/filename
    if (parts.length >= 3) {
      // New format: /audio/context/filename
      return {
        context: parts[parts.length - 2],
        filename: parts[parts.length - 1]
      };
    } else {
      // Old format: /audio/filename
      return {
        context: context, // Use provided context
        filename: parts[parts.length - 1]
      };
    }
  };
  
  // Check if the audio file exists when component mounts or value changes
  useEffect(() => {
    const checkFileExists = async () => {
      setChecking(true);
      
      if (!value) {
        setAudioName('');
        setFileExists(false);
        setChecking(false);
        return;
      }
      
      try {
        // Get context and filename from the path
        const { context, filename } = getContextAndFilename(value);
        
        if (!filename) {
          setAudioName('');
          setFileExists(false);
          setChecking(false);
          return;
        }
        
        const token = localStorage.getItem('token');
        
        // Make a HEAD request to check if the file exists
        await axios.head(`/api/check-audio/${context}/${filename}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        
        // If no error was thrown, file exists
        setAudioName(value);
        setFileExists(true);
      } catch (error) {
        console.warn('Audio file not found:', value);
        // Try legacy path as fallback
        try {
          const filename = value.split('/').pop();
          const token = localStorage.getItem('token');
          
          await axios.head(`/api/check-audio/${filename}`, {
            headers: { Authorization: `Bearer ${token}` }
          });
          
          // If no error, legacy file exists
          setAudioName(value);
          setFileExists(true);
        } catch (legacyError) {
          // File doesn't exist in either location, update the data
          updateMyData(index, id, '');
          setAudioName('');
          setFileExists(false);
        }
      } finally {
        setChecking(false);
      }
    };
    
    checkFileExists();
  }, [value, updateMyData, index, id, context]);
  
  // Clean up audio when component unmounts or when audio changes
  useEffect(() => {
    return () => {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
      if (timeUpdateRef.current) {
        clearInterval(timeUpdateRef.current);
      }
    };
  }, []);
  
  // Handle the case when audioName changes
  useEffect(() => {
    // If audio path changed or was removed, stop any current playback
    if (audioRef.current && (!audioName || audioName !== value)) {
      audioRef.current.pause();
      audioRef.current = null;
      setIsPlaying(false);
    }
    
    // Update local state if value from props changes
    if (value !== audioName) {
      setAudioName(value || '');
    }
  }, [value, audioName]);

  const handleAudioUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Check if the file is an audio file
    const fileType = file.type;
    if (!fileType.startsWith('audio/')) {
      alert('Please select an audio file');
      event.target.value = '';
      return;
    }

    setLoading(true);
    const formData = new FormData();
    formData.append('audioFile', file);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`/api/upload-audio?context=${context}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.audioPath) {
        setAudioName(response.data.audioPath);
        updateMyData(index, id, response.data.audioPath);
        alert('Audio uploaded successfully!');
      }
    } catch (error) {
      console.error('Error uploading audio:', error);
      alert('Error uploading audio file');
    } finally {
      setLoading(false);
      event.target.value = '';
    }
  };

  const handlePlay = () => {
    if (audioName) {
      // If already playing, stop the current playback
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
        setIsPlaying(false);
        setCurrentTime(0);
        clearInterval(timeUpdateRef.current);
        return;
      }
      
      // Create new audio instance and play
      const audio = new Audio(audioName);
      audioRef.current = audio;
      
      // Set up event handlers
      audio.onplay = () => setIsPlaying(true);
      audio.onpause = () => setIsPlaying(false);
      audio.onended = () => {
        setIsPlaying(false);
        audioRef.current = null;
        setCurrentTime(0);
        clearInterval(timeUpdateRef.current);
      };
      
      // Get duration when metadata is loaded
      audio.onloadedmetadata = () => {
        setDuration(audio.duration);
      };
      
      // Setup time update interval
      timeUpdateRef.current = setInterval(() => {
        if (audio && !audio.paused) {
          setCurrentTime(audio.currentTime);
        }
      }, 500);
      
      // Handle errors
      audio.onerror = (err) => {
        console.error('Error playing audio:', err);
        alert('Error playing audio file');
        setIsPlaying(false);
        audioRef.current = null;
      };
      
      // Play the audio
      audio.play().catch(err => {
        console.error('Error playing audio:', err);
        alert('Error playing audio file');
        setIsPlaying(false);
        audioRef.current = null;
        clearInterval(timeUpdateRef.current);
      });
    }
  };

  const handleRemove = () => {
    if (audioName) {
      // Stop playback if currently playing
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
        setIsPlaying(false);
        setCurrentTime(0);
        clearInterval(timeUpdateRef.current);
      }
      
      // Just clear the reference, don't delete the file as it might be used elsewhere
      setAudioName('');
      updateMyData(index, id, '');
    }
  };

  // Open the audio library to select existing audio
  const handleOpenLibrary = () => {
    if (showAudioLibrary) {
      showAudioLibrary(index, id, context);
    }
  };

  // Extract filename from path for display
  const getDisplayName = (path) => {
    if (!path) return '';
    const parts = path.split('/');
    return parts[parts.length - 1];
  };

  return (
    <div className="audio-upload-container">
      {checking ? (
        <div className="audio-checking">
          <span className="spinner"></span> Checking...
        </div>
      ) : audioName && fileExists ? (
        <>
          <div className="audio-filename" title={audioName}>
            {getDisplayName(audioName).substring(0, 15)}...
          </div>
          <div className="audio-controls">
            <button 
              className={`btn btn-sm ${isPlaying ? 'btn-success' : 'btn-primary'}`}
              onClick={handlePlay} 
              title={isPlaying ? "Stop audio" : "Play audio"}
            >
              {isPlaying ? '■' : '▶'}
            </button>
            <button 
              className="btn btn-sm btn-danger" 
              onClick={handleRemove} 
              title="Remove audio"
            >
              ✕
            </button>
          </div>
          {isPlaying && duration > 0 && (
            <div className="audio-progress">
              <div className="progress" style={{ height: '5px', marginTop: '3px' }}>
                <div 
                  className="progress-bar" 
                  role="progressbar" 
                  style={{ width: `${(currentTime / duration) * 100}%` }}
                  aria-valuenow={currentTime}
                  aria-valuemin="0" 
                  aria-valuemax={duration}
                ></div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="upload-controls">
          <div className="library-upload-buttons">
            <input
              type="file"
              id={`audio-upload-${index}`}
              accept="audio/*"
              onChange={handleAudioUpload}
              style={{ display: 'none' }}
            />
            <button 
              className="btn btn-sm btn-outline-secondary"
              onClick={handleOpenLibrary}
              title="Select from library"
            >
              Library
            </button>
            <label 
              htmlFor={`audio-upload-${index}`} 
              className="audio-upload-btn"
            >
              {loading ? (
                <span className="loading-text">Uploading...</span>
              ) : (
                <>
                  <span className="upload-icon">🎵</span>
                  <span className="upload-text">Upload</span>
                </>
              )}
            </label>
          </div>
        </div>
      )}
    </div>
  );
};

export default AudioUpload;